<template>
  <v-card width="100%" height="100%" class="rounded-0">
    <v-toolbar color="guacamole">
      <v-toolbar-title>Map</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <div style="height: 500px; width: 100%">
        <l-map
          ref="mapcontrolMap"
          v-if="showMap"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          style="height: 100%"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
        >
        <l-control-layers position="topright"></l-control-layers>

          <l-control-zoom position="bottomright"></l-control-zoom>
          <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
          />
          <l-marker
            v-for="(marker, index) in markers"
            :lat-lng.sync="marker.position"
            :draggable="false"
            :visible="marker.visible"
            :key="index"
          ></l-marker>
        </l-map>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { sync, get, call } from "vuex-pathify";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LControlZoom, LControlLayers } from "vue2-leaflet";

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  name: "MapControl",
  props: {
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LControlLayers
  },
  data: function () {
    console.log('we got the following values from the props')
    console.log(this.lat)
    console.log(this.lng)

    return {
      zoom: 8.5,
      // center: latLng(38.682613, 22.660929),
      center: latLng(this.lat, this.lng),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showMap: true,
      currentZoom: 11.5,
      currentCenter: this.mapCenter,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      markers: [],
      newmarkers: [],
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        {
          name: 'Esri.WorldImagery',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        }
      ],
    };
  },
  methods: {
    // ...call('mapform', [
    //   'setMarkers'
    // ]),
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    setMarker () {
        const newMarker = {
          position: { lat: this.lat, lng: this.lng },
          draggable: false,
          visible: true,
        };
        if (this.markers.length > 0) {
          return;
        }
        this.markers.push(newMarker);
    }
  },
  watch: {
    foobar (val) {
      console.log(val)
    },
    mymarker(val) {
        //this.addMarker({latlng: { lat: val.position.lat, lng: val.position.lng}})
        this.markers.push(val)
        // console.log(val)
    },
    mapCenter(val) {
        this.centerUpdate(val)
        this.$nextTick(() => {
          this.$refs.mapcontrolMap.mapObject.invalidateSize();
      });
    }
  },
  created () {
    this.setMarker()
  },
  mounted() {
  },
  computed: {
  },
};
</script>
