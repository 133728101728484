<template>
    <v-card width="100%" class="rounded-0">
        <!-- <v-card-title>Incident Control Panel</v-card-title> -->
        <v-toolbar
            color="biscuit"
            class="justify-center">
            <v-toolbar-title>Report control panel</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <p>
                ID: <span class="font-weight-black">{{ report.id }}</span>
            </p>
            <v-divider class="mb-2" />
            <p>You can transform this report to an incident with the following action.</p>
            <p>All known fields will be populated with values from this report.</p>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn color="biscuit" @click.prevent="makeIncident(report.id)">
                Transform to Incident
                <v-progress-circular color="guacamole" v-if="loading" indeterminate/>
            </v-btn>
            <!-- <v-btn color="guacamole" @click.stop="editIncident">
                Edit
            </v-btn> -->
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: "IncidentInfo",
    data: () => ({
        loading: false
    }),
    computed: {
        ...mapGetters({
            report: "reports/getReport"
        }),
    },
    methods: {
        makeIncident (id) {
            // Emit signal to ViewReport for confirmation and additional actions
            this.$root.$emit("generate-incident", id)
        },
        dateformat (timestamp) {
            if (timestamp) {
                let _timestamp = DateTime.fromISO(timestamp).toFormat("dd/MM/yyyy HH:mm:ss")
                return _timestamp
            }
        },
    }
}
</script>
