<template>
    <v-container fluid>
        <v-row fill-height>
            <v-col cols="7" fill-height>
                <v-card>
                    <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">Contact information</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item-title><b>Name and Surname:</b> {{report.name_surname}}</v-list-item-title>
                            <v-list-item-title><b>Agency/Organization:</b> {{report.agency_organization}}</v-list-item-title>
                            <v-list-item-title><b>Town/village:</b> {{report.town}}</v-list-item-title>
                            <v-list-item-title><b>Email:</b> {{report.email}}</v-list-item-title>
                            <v-list-item-title><b>Phone:</b> {{report.phone}}</v-list-item-title>
                            <v-list-item-title><b>Learnt about event by:</b> <br>
                            <p>{{ report.learnOfEvent }}</p></v-list-item-title>
                        </v-list>
                    </v-card-text>
                </v-card>

                <v-card>
                    <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">Incident information</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item-title><b>Day of event (approx.):</b> {{report.dateOfEvent}}</v-list-item-title>
                            <v-list-item-title><b>Dead animal involved:</b> {{report.deadAnimalInvolved}}</v-list-item-title>
                            <v-list-item-title><b>Description:</b><br><p>{{report.noAnimalAndState}}</p></v-list-item-title>
                            <v-divider class="pb-2" />
                            <v-list-item-title><b>Bait found:</b> {{report.baitFound}}</v-list-item-title>
                            <v-list-item-title><b>Description:</b><br><p>{{report.noBaitsAndType}}</p></v-list-item-title>
                        </v-list>
                    </v-card-text>
                </v-card>

                <v-card>
                    <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">Event location</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item-title><b>Country:</b> {{report.country}}</v-list-item-title>
                            <v-list-item-title><b>Region:</b> {{report.region}}</v-list-item-title>
                            <v-list-item-title><b>County:</b> {{report.county}}</v-list-item-title>
                            <v-list-item-title><b>Municipality:</b> {{report.municipality}}</v-list-item-title>
                            <v-list-item-title><b>Latitude:</b> {{report.lat}}</v-list-item-title>
                            <v-list-item-title><b>Longitude:</b> {{report.lng}}</v-list-item-title>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="5">
                <report-control />
                <map-component-props :lat="report.lat" :lng="report.lng" />
            </v-col>
        </v-row>

        <v-dialog
            v-model="transform"
            persistent
            width="600"
            style="z-index: 1301"
        >
            <v-card>
                <v-toolbar color="guacamole" class="justify-center mb-2 rounded-0">
                    <v-toolbar-title>Generate Incident</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p>You are about to generate an Incident out of this report. If you
                        are sure, confirm to continue.</p>
                    <v-row v-if="!loading" class="justify-center">
                        <v-col>
                            <v-btn color="error" @click.prevent="cancel">Cancel</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="guacamole" @click.prevent="generate">Continue</v-btn>
                        </v-col>
                    </v-row>
                    <p v-if="loading">Generating the new incident based on the report</p>
                    <v-progress-circular indeterminate v-if="loading"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
import {mapGetters} from 'vuex'
import MapComponentProps from '../components/MapComponentProps.vue'
import ReportControl from '../components/ReportControl.vue'

export default {
  props: ["id"],
  components: {
      MapComponentProps,
      ReportControl
  },
  data: function () {
    return {
        transform: false,    // dialog control for generation
        report_id: '',
        error: ''
    };
  },
  methods: {
      cancel () {
          this.transform = false
      },
      generate () {
          this.$store.dispatch('reports/generateIncident', this.report_id)
            .then(() => {
                this.$router.push({name: 'ViewIncident', params: {'entrycode': this.newincident}})
            }).catch(error => {
                this.error = error
            })
      }
  },
  computed: {
    ...mapGetters({
        report: "reports/getReport",
        loading: "reports/getLoading",
        newincident: "reports/getNewIncident"
    })
  },
  mounted () {
      this.$root.$on('generate-incident', (id) => {
        // this.$store.dispatch('reports/generateIncident', id)
        this.report_id = id
        this.transform = true
      })
  }
};
</script>


<style scoped>
.cardtitle {
   background-color: #7eb09f;   
}
</style>